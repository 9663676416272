import http from "./http";

export interface NotifyFilter {
  tidbits: boolean;
  actions: boolean;
  notes: boolean;
  events: boolean;
  links: boolean;
  past: boolean;
  future: boolean;
  overdue: boolean;
  direction: string;
  sys_id_note?: string;
  sys_id_references?: string[];
  days?: number;
}

export interface NotifyGraphNode {
  id: string;
  name: string;
  group: string;
}

export interface NotifyGraphLink {
  source: string;
  target: string;
}

export interface NotifyGraph {
  nodes: NotifyGraphNode[];
  links: NotifyGraphLink[];
}

export class Storage {
  static async getGraph(sys_id_note: string, filter: NotifyFilter, days: number): Promise<NotifyGraph> {
    // console.log("getGraph");
    filter.days = days;
    filter.sys_id_note = sys_id_note;
    filter.sys_id_references = [];
    // console.log(filter);

    const graphResponse: any = await http.post(`public/v1/graph`, filter);
    // console.log(graphResponse);

    return <NotifyGraph>graphResponse.data;
  }

  static async applyToBeta(email: string): Promise<string> {
    // console.log("applyToBeta");
    const emailResponse: any = await http.post(`public/v1/email`, { email: email });
    // console.log(emailResponse);

    return <string>emailResponse.data;
  }
}