import React, { useState, useEffect } from "react";
import { render } from "react-dom";

import { gtag, install } from 'ga-gtag';
import { Row, Col } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';

import ViewNetworkPanel from "./react/editor/components/ViewNetworkPanel";
import { Storage } from "./utils/storage";

install('G-60RWZGDQVP');

const App = () => {
  const [sent, setSent] = useState(false);

  const applyForBeta = async() => {
    const response = await Storage.applyToBeta(document.getElementById("email").value);
    setSent(true);
  }

  useEffect(() => {
    
  }, []);

  return (
    <React.Fragment>
      <ViewNetworkPanel />
      <Row>
        <Col style={{zIndex: "1020"}}>
          <div style={{height: "40px"}}>&nbsp;</div>
          <div className="row mb-5">
            <div className="col text-center">
              <img src="./favicon-120.png" className="mb-3 me-1" height="23px" width="23px" />
              <span className="ms-1 fs-1 opacity-75 text-light">notify</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col text-center">
                  <span className="fs-1 fw-bolder rounded-4 p-2" style={{backgroundColor: "rgba(0, 0, 73, 0.5)"}}>
                    <span style={{color: "#FFFFFF"}}>A new surface for work</span>
                  </span>
                </div>
              </div>
              <div className="row mb-5 mt-3">
                <div className="col text-center">
                  <span className="fs-5 rounded-4 p-2" style={{backgroundColor: "rgba(0, 0, 73, 0.5)"}}>
                    <span className="opacity-75" style={{color: "#FFFFFF"}}>Supercharged productivity. Built for humans, powered by machines.</span>
                  </span>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-xl-3 col-md-3 col-sm-1">
                </div>
                <div className="col text-center">
                  {sent == false &&
                    <React.Fragment>
                      <div className="mb-3 ms-2 me-2">
                        <label htmlFor="email" className="form-label text-light rounded-4 p-2" style={{backgroundColor: "rgba(0, 0, 73, 0.5)"}}>Apply to join private beta</label>
                        <input type="email" className="form-control" id="email" placeholder="Work email" />
                      </div>
                      <button onClick={applyForBeta} className="btn btn-light opacity-75">Join</button>
                    </React.Fragment>
                  }
                  {sent == true &&
                    <span className="text-light rounded-4 p-2" style={{backgroundColor: "rgba(0, 0, 73, 0.5)"}}>Request sent</span>
                  }
                </div>
                <div className="col-xl-3 col-md-3 col-sm-1">
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col text-center">
                  <a href="/privacy.html" target="_blank" className="text-decoration-none text-light">privacy policy</a>
                  |
                  <a href="/tos.html" target="_blank" className="text-decoration-none text-light">terms of service</a>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

render(<App />, document.getElementById("root"));
