import axios from "axios";

// TODO: The problem with this approach is that this file is "printed" when the app loads, so any changes to local storage
// are only applied with a hard refresh on the browser
export default axios.create({
  baseURL: "https://app.getnotify.ai/", //"http://localhost:3000/", // 
  headers: {
    "Content-Type": "application/json",
  }
});
