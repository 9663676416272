import React, { useEffect, useState, useRef } from "react";

import * as THREE from 'three';

import { UnrealBloomPass } from '../../../three/UnrealBloomPass.js';
import { ForceGraph3D } from 'react-force-graph';

import { Storage } from "../../../utils/storage";

const ViewNetworkPanel = (props) => {
  const [graph, setGraph] = useState(null);
  const [effectsApplied, setEffectsApplied] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  
  const fgRef = useRef();
  const distance = 700;
  
  const loadGraphData = async() => {
    // let graphData = await Storage.getGraph(null, {}, props.filterGraphDays, references_referenced);
    // console.log(JSON.stringify(graphData));
    // setGraph(graphData);

    if (effectsApplied == false) {
      const bloomPass = new UnrealBloomPass();
      bloomPass.strength = 0.5;
      bloomPass.radius = 0.25;
      bloomPass.threshold = 0.1;
      fgRef.current.postProcessingComposer().addPass(bloomPass);
      setEffectsApplied(true);
    }

    fgRef.current.cameraPosition({ z: distance });

    // camera orbit
    let angle = 0;
    setInterval(() => {
      fgRef.current.cameraPosition({
        x: distance * Math.sin(angle),
        z: distance * Math.cos(angle)
      });
      angle += Math.PI / 300;
    }, 50);
  }

  // Handler to call on window resize
  function handleResize() {
    // Set window width/height to state
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  }

  function getNodeOpacity(node) {
    return node.opacity;
  }

  function getNodeColor(node) {
    if (node.group == 1) {
      // This is a topic
      return "#2EB67D";
    } else {
      return "#36C5F0";
    }
  }

  function nodeThreeObject(node) {
    const sphereGeometry = new THREE.SphereGeometry(node.actions_incomplete == 0 ? 3 : (node.actions_incomplete * 3));
    const sphereMaterial = new THREE.MeshBasicMaterial({
      color: getNodeColor(node),
      transparent: true,
      opacity: getNodeOpacity(node),
    });
    const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
    return sphereMesh;
  }

  useEffect(() => {
    // console.log(props.viewIndex);
    window.addEventListener("resize", handleResize);

    loadGraphData();
  }, []);

  return (
    <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
      <ForceGraph3D
        height={height}
        width={width}
        ref={fgRef}
        jsonUrl="/assets/data.json"
        backgroundColor="#000049"
        nodeRelSize={2}
        nodeVal={(node) => node.actions_incomplete == 0 ? 1 : (node.actions_incomplete * node.actions_incomplete)}
        nodeAutoColorBy="group"
        nodeThreeObject={nodeThreeObject}
        showNavInfo={false}
      />
    </div>
  );
};

export default ViewNetworkPanel;
